import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { BananaPaginator, BananaTable, BananaTipButton } from 'banana-framework';
import moment from 'moment';

import { ShipmentsFilter } from 'components/presentational/shipments-overview';

import * as actions from './shipments-actions';

const ShipmentsOverview = (props) => {
  const history = useHistory();
  const [redirectShipmentId, setRedirectShipmentId] = useState(false);
  const [shipmentType, setShipmentType] = useState(' ');

  const getAllShipments = () =>
    props.getAllShipments({
      page: props.page,
      sorting: props.sorting,
      location_pattern: props.location_pattern,
      shipment_type: shipmentType,
      from_date: props.from_date,
      to_date: props.to_date,
      box_external_id: props.box_external_id,
      battery_external_id: props.battery_external_id,
    });

  // on mount
  useEffect(() => {
    props.sharedResetAppState();
    getAllShipments();
  }, []);

  useEffect(() => {
    if (redirectShipmentId !== null) setTimeout(() => setRedirectShipmentId(null));
  }, [redirectShipmentId]);

  const onAddDelivery = () => history.push('/shipment/create/delivery');
  const onAddReturn = () => history.push('/shipment/create/return');

  // On dependcies changed, getAllShipments
  useEffect(() => {
    if (shipmentType === 'delivery' || shipmentType === 'return') props.changeShipmentType(shipmentType);
    getAllShipments();
  }, [
    shipmentType,
    props.location_pattern,
    props.to_date,
    props.from_date,
    props.box_external_id,
    props.battery_external_id,
    props.sorting,
    props.page,
  ]);

  const changeToDate = (date) => {
    if (date === null) props.changeToDate(moment());
    else props.changeToDate(date);
  };
  const changeFromDate = (date) => {
    if (date === null) props.changeToDate(moment());
    else props.changeFromDate(date);
  };
  const setSortingType = (sortingType) => props.setSortingType(props.sorting, sortingType);

  return (
    <div>
      {redirectShipmentId && <Redirect push to={`/shipment/edit/${redirectShipmentId}`} />}
      <ShipmentsFilter
        toDate={props.to_date}
        fromDate={props.from_date}
        changeToDate={changeToDate}
        changeFromDate={changeFromDate}
        location={props.location_pattern}
        shipmentType={shipmentType}
        boxExternalId={props.box_external_id}
        changeShipmentType={setShipmentType}
        updateBoxExternalId={props.updateBoxExternalId}
        resetRequestState={props.resetRequestState}
        batteryExternalId={props.battery_external_id}
        changeLocationFunction={props.changeLocationPattern}
        updateBatteryExternalId={props.updateBatteryExternalId}
      />
      <BananaTable
        type="shipment"
        setSortingType={setSortingType}
        data={props.shipments.shipments}
        currentSortingType={props.sorting}
        showDeletePopup={() => {}}
      />
      <BananaPaginator
        maxElements={20}
        activeComponent={props.page}
        elementAmount={props.shipments.total_pages}
        setActiveElement={props.setActiveFilterPaginatorElement}
      />
      <BananaTipButton
        buttonContent={[
          {
            color: 'violet',
            title: 'Add delivery shipment',
            icon: 'fa-caret-square-o-right',
            onAddDelivery,
          },
          {
            color: 'violet',
            title: 'Add return shipment',
            icon: 'fa-caret-square-o-left',
            onAddReturn,
          },
        ]}
      />
    </div>
  );
};

ShipmentsOverview.propTypes = {
  page: PropTypes.number.isRequired,
  sorting: PropTypes.string.isRequired,
  to_date: PropTypes.object.isRequired,
  shipments: PropTypes.object.isRequired,
  from_date: PropTypes.object.isRequired,
  box_external_id: PropTypes.string.isRequired,
  location_pattern: PropTypes.string.isRequired,
  battery_external_id: PropTypes.string.isRequired,

  changeToDate: PropTypes.func.isRequired,
  changeFromDate: PropTypes.func.isRequired,
  setSortingType: PropTypes.func.isRequired,
  getAllShipments: PropTypes.func.isRequired,
  resetRequestState: PropTypes.func.isRequired,
  changeShipmentType: PropTypes.func.isRequired,
  sharedResetAppState: PropTypes.func.isRequired,
  updateBoxExternalId: PropTypes.func.isRequired,
  changeLocationPattern: PropTypes.func.isRequired,
  updateBatteryExternalId: PropTypes.func.isRequired,
  setActiveFilterPaginatorElement: PropTypes.func.isRequired,
};

const mapStateToProps = ({ shipmentsData }) => ({
  page: shipmentsData.page,
  sorting: shipmentsData.sorting,
  to_date: shipmentsData.to_date,
  shipments: shipmentsData.shipments,
  from_date: shipmentsData.from_date,
  box_external_id: shipmentsData.box_external_id,
  location_pattern: shipmentsData.location_pattern,
  battery_external_id: shipmentsData.battery_external_id,
});

const mapDispatchToProps = {
  changeToDate: actions.changeToDate,
  changeFromDate: actions.changeFromDate,
  setSortingType: actions.setSortingType,
  getAllShipments: actions.getAllShipments,
  resetRequestState: actions.resetRequestState,
  changeShipmentType: actions.changeShipmentType,
  sharedResetAppState: actions.sharedResetAppState,
  updateBoxExternalId: actions.updateBoxExternalId,
  changeLocationPattern: actions.changeLocationPattern,
  updateBatteryExternalId: actions.updateBatteryExternalId,
  setActiveFilterPaginatorElement: actions.setActiveFilterPaginatorElement,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsOverview);
