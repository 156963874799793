import {
  LocationType,
  shipmentDataInitialState,
  ShipmentLabel,
  ShipmentStateType,
  ShipmentType,
} from 'components/containers/shipment/shipment-data';
import moment from 'moment';

/**
 * @implNode
 *
 * Please do not export from here, but rather move the types and initial states
 * to their proper modules (check components/containers/shipment/shipment-data)
 *
 * That way we start to structure responsibilities and organize the code better.
 */

type SortingType = 'shipment_date+desc';
type ShipmentsStateType = {
  shipments: {};
  sorting: SortingType;
  location_pattern: string;
  shipment_type: string;
  from_date: moment.Moment;
  to_date: moment.Moment;
  box_external_id: string;
  battery_external_id: string;
  page: number;
  sortingReturn: SortingType;
  pageReturn: number;
};

type LoginStateType = {
  email: string;
  password: string;
};

type AppStateType = {
  appState: string;
  activeHeaderComponent: 'operations';
  isRouteMenuOpened: boolean;
  error: string;
  isVisibleDialogWindow: boolean;
  notification: string;
  isLoading: boolean;
};

type NewBoxStateType = {
  newBoxId: string;
  locations: { locations: LocationType[] }; // TODO: move locations.locations to locations
  cablesData: { [key: number]: number };
  batteryIds: string[];
  choosenLocationId: string | null;
  shipmentDate: moment.Moment;
};

type NewMockRentalStateType = {
  rentalDate: moment.Moment;
  battery: string;
  types: unknown[];
  choosenType: number; // TODO: if this is index, rename to 'chosenTypeIndex' (ORM problems)
  defaultLocation: LocationType | {};
  lateFee: boolean;
};

type OrderType = unknown;
type GoodsOrdersOverviewStateType = {
  orders: OrderType[];
  shipments: ShipmentType[];
  checkedShipments: ShipmentType[];
};

type GoodsOrderStateType = {
  partners: unknown[];
  statuses: unknown[];
  units: unknown[];
  partnerID: string | null;
  partnerOrderID: string;
  deliveryDateTime: moment.Moment;
  orderDate: moment.Moment;
  status: string; // TODO: was '0', what is this?
  quantity: string;
  chosenUnit: number; // TODO: if this is index we need to rename (ORM problems)
};

type AssignShipmentsToGoodsOrderStateType = {
  orders: OrderType[];
  unassignedShipments: ShipmentType[];
  shipmentsAssignedToSelectedOrder: ShipmentType[];
  selectedOrder: number; // TODO: if this is index we need to rename (ORM problems)
};

type ValoraInvoiceType = unknown;
type ValoraInvoicesStateType = {
  invoices: { valora_invoices: ValoraInvoiceType[] }; // TODO: move invoices.
  paidBool: boolean | null;
};

type RentalsType = unknown;
type CountryType = unknown;
type AllRentalsStateType = {
  rentalsData: {
    rentals: RentalsType[];
    totalPages: number;
    activeCountries: CountryType[];
  };
  rentalsStats: {}; // TODO: constrain type
  search: string;
  searchLocation: string;
  dateRange: unknown | 'last365Days';
  rentalType: ['pickup', 'return']; // TODO: move to constants?
  country: CountryType | 'all';

  user: ['anonymous', 'registered']; // TODO:: users should be managed differently
  status: ['succeeded']; // TODO: check what's this from

  page: number;
};

const shipmentsDataInitialState: ShipmentsStateType = {
  shipments: {},
  sorting: 'shipment_date+desc',
  location_pattern: '',
  shipment_type: '',
  from_date: moment().subtract(14, 'days'),
  to_date: moment(),
  box_external_id: '',
  battery_external_id: '',
  page: 1,
  sortingReturn: 'shipment_date+desc',
  pageReturn: 1,
};

// TODO: as Labels get moved from here to their modules, change root-reducer accordingly
// (use the same pattern)
enum LoginLabel {
  STATE = 'loginData',
}
enum AppLabel {
  STATE = 'appData',
}
enum ShipmentsLabel {
  STATE = 'shipmentsData',
}
enum NewBoxLabel {
  STATE = 'newBoxIdData',
}
enum NewMockRentalLabel {
  STATE = 'newMockRentalData',
}
enum GoodsOrdersOverviewLabel {
  STATE = 'goodsOrdersOverviewData',
}
enum GoodsOrderLabel {
  STATE = 'goodsOrderData',
}
enum AssignShipmentsToGoodsOrderLabel {
  STATE = 'assignShipmentsToGoodsOrderData',
}
enum ValoraInvoicesLabel {
  STATE = 'valoraInvoicesData',
}
enum AllRentalsLabel {
  STATE = 'allRentalsData',
}

export type GlobalState = {
  [LoginLabel.STATE]: LoginStateType;
  [AppLabel.STATE]: AppStateType;
  [ShipmentsLabel.STATE]: ShipmentsStateType;
  [ShipmentLabel.STATE]: ShipmentStateType;
  [NewBoxLabel.STATE]: NewBoxStateType;
  [NewMockRentalLabel.STATE]: NewMockRentalStateType;
  [GoodsOrdersOverviewLabel.STATE]: GoodsOrdersOverviewStateType;
  [GoodsOrderLabel.STATE]: GoodsOrderStateType;
  [AssignShipmentsToGoodsOrderLabel.STATE]: AssignShipmentsToGoodsOrderStateType;
  [ValoraInvoicesLabel.STATE]: ValoraInvoicesStateType;
  [AllRentalsLabel.STATE]: AllRentalsStateType;
};

const GlobalInitialState: GlobalState = {
  [LoginLabel.STATE]: {
    email: '',
    password: '',
  },
  appData: {
    appState: '',
    activeHeaderComponent: 'operations',
    isRouteMenuOpened: false,
    error: '',
    isVisibleDialogWindow: false,
    notification: '',
    isLoading: false,
  },
  [ShipmentsLabel.STATE]: shipmentsDataInitialState,
  [ShipmentLabel.STATE]: shipmentDataInitialState,
  newBoxIdData: {
    newBoxId: '',
    locations: {
      locations: [],
    },
    cablesData: {
      4: 24,
    },
    batteryIds: [],
    choosenLocationId: null,
    shipmentDate: moment(),
  },
  newMockRentalData: {
    rentalDate: moment().subtract(11, 'minutes'),
    battery: '',
    types: [],
    choosenType: 0,
    defaultLocation: {},
    lateFee: false,
  },
  goodsOrdersOverviewData: {
    orders: [],
    shipments: [],
    checkedShipments: [],
  },
  goodsOrderData: {
    partners: [],
    statuses: [],
    units: [],
    partnerID: null,
    partnerOrderID: '',
    deliveryDateTime: moment().add(1, 'week'),
    orderDate: moment(),
    status: '0',
    quantity: '',
    chosenUnit: 0,
  },
  assignShipmentsToGoodsOrderData: {
    orders: [],
    unassignedShipments: [],
    shipmentsAssignedToSelectedOrder: [],
    selectedOrder: 0,
  },
  valoraInvoicesData: {
    invoices: {
      valora_invoices: [],
    },
    paidBool: null,
  },
  allRentalsData: {
    rentalsData: {
      rentals: [],
      totalPages: 0,
      activeCountries: [],
    },
    rentalsStats: {},
    search: '',
    searchLocation: '',
    dateRange: 'last365Days',
    rentalType: ['pickup', 'return'],
    country: 'all',

    user: ['anonymous', 'registered'],
    status: ['succeeded'],

    page: 1,
  },
};

export default GlobalInitialState;
